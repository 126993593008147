import React from 'react'
import { Route, Routes } from 'react-router-dom'


// Rutas Operativas
import CambioPrCo from '../CambioPrCo.js';

import Entrada from '../views/Entrada.js'
import Identificarse from "../views/Identificarse.js";
import MisProductos from "../views/Productos/MisProductos.js";
import MisPedidos from "../views/Pedidos/MisPedidos.js";
import FechasEntrega from "../views/FechasEntrega/FechasEntrega.js";
import MisMapas from "../views/Mapas/MisMapas.js";
/////////////////import MisMapas1 from "../views/Mapas/MisMapas1.js";

import InfProductos from "../views/Informes/InfProductos.js";
import InfPedidos from '../views/Informes/InfPedidos.js';
import InfEntregas from '../views/Informes/InfEntregas.js';
import InfProductosYMercados from "../views/Informes/InfProductosYMercados.js";
import InfVentasClientes from '../views/Informes/InfVentasClientes.js';
import InfClientes from '../views/Informes/InfClientes.js';

import FacResumidas from '../views/Facturas/FacResumidas.js';
import FacDesglosadas from '../views/Facturas/FacDesglosadas.js';
import Clientes from '../views/Clientes/Clientes.js';

export default function Rutas(props) {


  return (
    <div>

    <Routes>
      <Route path="/CambioPrCo" element={<CambioPrCo/>} />

      <Route path="/" element={<Entrada/>} />
      <Route path="/Identificarse" element={<Identificarse/>} />
      <Route path="/MisProductos" element={<MisProductos/>} />
      <Route path="/MisPedidos" element={<MisPedidos/>} />
      <Route path="/InfProductos" element={<InfProductos/>} />
      <Route path="/InfPedidos" element={<InfPedidos/>} />
      <Route path="/InfEntregas" element={<InfEntregas/>} />
      <Route path="/InfProductosYMercados" element={<InfProductosYMercados/>} />
      <Route path="/InfVentasClientes" element={<InfVentasClientes/>} />
      <Route path="/InfClientes" element={<InfClientes/>} />
      <Route path="/FacResumidas" element={<FacResumidas/>} />
      <Route path="/FacDesglosadas" element={<FacDesglosadas/>} />
      <Route path="/Clientes" element={<Clientes/>} />
      <Route path="/FechasEntrega" element={<FechasEntrega/>} />

      <Route path="/MisMapas" element={<MisMapas/>} />
    </Routes>
</div>
  );
};

